<template>
  <div
    class="radar"
    :style="{
      width: size,
      height: size,
    }"
  >
    <div class="radar__grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="radar__obj radar__obj--1"></div>
    <div class="radar__obj radar__obj--2"></div>
    <div class="radar__obj radar__obj--3"></div>
    <div class="scanner"></div>
  </div>
</template>

<script>
  export default {
    name: "Radar",
    props: {
      size: {
        type: [Number, String],
        default: "100px",
      },
    },
    data() {
      return {};
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  /*   $--color-900: #abff51;
  $--color-300: #189317;
  $--color-100: #30ac0c;
  $--color-10: #0e2e0c; */

  $--color-900: #51e5ff;
  $--color-300: #178493;
  $--color-100: #0c79ac;
  $--color-10: #0e2e0c;
  .radar {
    position: relative;
    overflow: hidden;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid $--color-900;
    background-image: radial-gradient($--color-100 50%, $--color-10 80%, #000 90%);
    box-shadow: 0.1rem 0.6rem 0.6rem inset rgba(0, 0, 0, 0.24);
  }
  .radar__grid {
    width: 100%;
    height: 100%;
  }
  .radar__grid::before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: $--color-900;
    position: absolute;
    left: 50%;
  }
  .radar__grid::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: $--color-900;
    position: absolute;
    top: 50%;
  }
  .radar__grid > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    border: 1px solid $--color-900;
    border-radius: 50%;
  }
  .radar__grid > :nth-child(1) {
    width: 20%;
    height: 20%;
  }
  .radar__grid > :nth-child(2) {
    width: 40%;
    height: 40%;
  }
  .radar__grid > :nth-child(3) {
    width: 60%;
    height: 60%;
  }
  .radar__grid > :nth-child(4) {
    width: 80%;
    height: 80%;
  }

  .scanner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: conic-gradient(transparent 0deg 93%, $--color-900);
    animation: scan 3s linear infinite;
    transform-origin: center;
  }

  .radar__obj {
    position: absolute;
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #fff;
    box-shadow: 0 0 1rem 0.34rem #fff, 0 0 2rem 1rem $--color-900, 0 0 3rem 1.5rem $--color-300;

    filter: saturate(1.45);
    animation: blink 3s ease-out infinite;
    opacity: 0;
  }

  .radar__obj::after {
    color: $--color-900;
    font: normal 0.4rem/0 monospace;
    padding-left: 1rem;
    white-space: nowrap;
  }

  .radar__obj--1 {
    top: 14%;
    left: 59%;
    transform: scale(1.7);
    animation-delay: 0.21346s;
  }
  .radar__obj--1::after {
    content: "C/133";
  }

  .radar__obj--2 {
    top: 55%;
    left: 54%;
    transform: scale(1.3);
    animation-delay: 1.125s;
  }
  .radar__obj--2::after {
    content: "P/042";
  }
  .radar__obj--3 {
    top: 51%;
    left: 18%;
    transform: scale(1.5667);
    animation-delay: 2.29970405918s;
  }
  .radar__obj--3::after {
    content: "z/015";
  }

  /* Animations */
  @keyframes scan {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    12.5% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
  }
</style>

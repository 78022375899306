<template>
  <div id="data-view">
    <dv-full-screen-container>
      <div class="header">
        <dv-decoration-7>网站数据统计信息</dv-decoration-7>
      </div>
      <button class="login-button" @click="redirectToLogin">进入网站</button>
      <dv-border-box-1 class="container1">
        <div class="container1-main">
          <div class="dashboard dashboard-1">
            <div class="box">
              <div class="box-body">
                <div class="row">
                  <div v-for="(item, i) in digitalFlopData" class="col w-16" :key="i">
                    <dv-digital-flop :config="item" />
                    <div class="item-name">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="dashboad dashboard-2">
            <div class="row">
              <div class="col w-40 flex-column x-row">
                <!-- <div class="box">
                  <div class="box-header">
                    <div class="h3">趋势分析</div>
                    <div><dv-decoration-6 class="dv-decoration-6" /></div>
                  </div>
                  <div class="box-body">
                    <dv-charts :option="lineChart" />
                  </div>
                </div> -->
                <div class="box">
                  <div class="box-header">
                    <div class="h3">施工管理</div>
                    <div></div>
                  </div>
                  <div class="box-body">
                    <dv-scroll-board class="scroll-board" :config="scrollConfig" />
                  </div>
                </div>
                <div class="box">
                  <div class="box-header">
                    <div class="h3">BIM应用</div>
                    <div></div>
                  </div>
                  <div class="box-body">
                    <dv-scroll-board class="scroll-board" :config="scrollConfig2" />
                  </div>
                </div>
                <div class="box">
                  <div class="box-header">
                    <div class="h3">公路工程</div>
                    <div></div>
                  </div>
                  <div class="box-body">
                    <dv-scroll-board class="scroll-board" :config="scrollConfig3" />
                  </div>
                </div>
              </div>
              <div class="col w-20">
                <div class="box">
                  <div class="box-header">
                    <div class="h3">类占比</div>
                    <div></div>
                  </div>
                  <div class="box-body justify-content-center align-items-center">
                    <dv-active-ring-chart :config="ringChartConfig" style="width: 300px; height: 300px" />
                  </div>
                </div>
              </div>

              <div class="col w-40 flex-column x-row">
                <div class="box">
                  <div class="box-header">
                    <div class="h3">交通工程</div>
                    <div></div>
                  </div>
                  <div class="box-body">
                    <dv-scroll-board class="scroll-board" :config="scrollConfig4" />
                  </div>
                </div>
                <div class="box">
                  <div class="box-header">
                    <div class="h3">智能交通</div>
                    <div></div>
                  </div>
                  <div class="box-body"><dv-scroll-board class="scroll-board" :config="scrollConfig5" /></div>
                </div>
                <div class="box">
                  <div class="box-header">
                    <div class="h3">项目风采</div>
                    <div></div>
                  </div>
                  <div class="box-body"><dv-scroll-board class="scroll-board" :config="scrollConfig5" /></div>
                </div>
              </div>
            </div>
          </div>
          <div class="dashboad dashboard-3">
            <div class="box">
              <div class="box-body">
                <div class="row">
                  <div v-for="(item, i) in digitalFlopData2" class="col w-20" :key="i">
                    <dv-digital-flop :config="item" />
                    <div class="item-name">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </dv-border-box-1>
    </dv-full-screen-container>
  </div>
</template>

<script>
  import radar from "./componets/radar";

  export default {
    name: "DataView",
    components: {
      radar,
    },
    data() {
      return {
        cip: 207,
        cpv: 1850,
        cuv: 320,
        cvv: 2100,
        cjp: 25,
        tip: 690,
        tpv: 66388,
        tuv: 2725,
        tvv: 27932,
        tjp: 25,
        lineChart: {
          legend: {
            data: ["每日性能测试趋势"],
            textStyle: {
              fill: "#fff",
            },
          },
          xAxis: {
            data: ["10/01", "10/02", "10/03", "10/04", "10/05", "10/06", "10/07", "10/07", "10/08", "10/09", "10/10", "10/11", "10/12", "10/13", "10/14", "10/15"],
            boundaryGap: false,
            axisLine: {
              style: {
                stroke: "#999",
              },
            },
            axisLabel: {
              style: {
                fill: "#999",
              },
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            data: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              style: {
                stroke: "#999",
              },
            },
            axisLabel: {
              style: {
                fill: "#999",
              },
              formatter({ value }) {
                return value.toFixed(2);
              },
            },
            axisTick: {
              show: false,
            },
            min: 95,
            max: 100,
            interval: 0.5,
          },
          series: [
            {
              data: [99.56, 99.66, 99.84, 99.22, 99.11, 99.45, 99.44, 99.81, 99.84, 99.32, 99.14, 99.45, 99.15, 99.45, 99.64, 99.89],
              type: "line",
              name: "每日性能测试趋势",
              smooth: true,
              lineArea: {
                show: true,
                gradient: ["rgba(55, 162, 218, 0.6)", "rgba(55, 162, 218, 0)"],
              },
              linePoint: {
                radius: 4,
                style: {
                  fill: "#4D99FC",
                },
              },
            },
          ],
        },

        scrollConfig: {
          header: ["标题", "更新时间"],
          headerHeight: 30,
          headerBGC: "rgba(255, 255, 255, 0.2)",
          data: [
            ["test1", "2月前"],
            ["机电设备智慧管理", "4月前"],
            ["数字孪生隧道精准管控解决方案", "4月前"],
            ["蜘蛛人高空作业旧房改造墙体施工", "8月前"],
            ["实拍工程车登高车市政施工", "8月前"],
            ["施工建筑房地产建房实拍", "8月前"],
            ["建筑工程机械施工实拍", "8月前"],
            ["实拍工地施工建筑工人", "8月前"],
            ["现代建筑项目室内施工蓝图设计", "8月前"],
          ],
          index: true,
          align: ["center"],
          oddRowBGC: "rgba(9, 37, 50, 0.4)",
          evenRowBGC: "rgba(10, 32, 50, 0.3)",
        },
        scrollConfig2: {
          header: ["标题", "更新时间"],
          headerHeight: 30,
          headerBGC: "rgba(255, 255, 255, 0.2)",
          data: [
            ["京台高速公路泰安至枣庄段改扩建工程04", "1天前"],
            ["京台高速公路泰安至枣庄段改扩建工程03", "1天前"],
            ["京台高速公路泰安至枣庄段改扩建工程02", "1天前"],
            ["京台高速公路泰安至枣庄段改扩建工程01", "1天前"],
            ["广连高速简介01", "3天前"],
            ["crowd", "3天前"],
          ],
          index: true,
          align: ["center"],
          oddRowBGC: "rgba(9, 37, 50, 0.4)",
          evenRowBGC: "rgba(10, 32, 50, 0.3)",
        },
        scrollConfig3: {
          header: ["标题", "更新时间"],
          headerHeight: 30,
          headerBGC: "rgba(255, 255, 255, 0.2)",
          data: [
            ["弥勒至楚雄高速公路玉溪至楚雄段05", "1天前"],
            ["弥勒至楚雄高速公路玉溪至楚雄段04", "1天前"],
            ["弥勒至楚雄高速公路玉溪至楚雄段03", "1天前"],
            ["弥勒至楚雄高速公路玉溪至楚雄段02", "1天前"],
            ["弥勒至楚雄高速公路玉溪至楚雄段01", "1天前"],
            ["长治市国道环线公路改扩建工程04", "1天前"],
            ["长治市国道环线公路改扩建工程03", "1天前"],
            ["长治市国道环线公路改扩建工程02", "1天前"],
            ["长治市国道环线公路改扩建工程01", "1天前"],
            ["长治市国道环线公路改扩建工程04", "1天前"],
            ["AI交通毫米波雷达-交通运行监测及预警系统", "4个月前"],
            ["城市道路建设拔桩机施工作业实拍", "8个月前"],
            ["市政车位标识划定-交通运行监测及预警系统", "8个月前"],
            ["实拍延时工地建设者", "8个月前"],
            ["警钟长鸣企业施工安全生产防微杜渐", "8个月前"],
          ],
          index: true,
          align: ["center"],
          oddRowBGC: "rgba(9, 37, 50, 0.4)",
          evenRowBGC: "rgba(10, 32, 50, 0.3)",
        },

        scrollConfig4: {
          header: ["标题", "更新时间"],
          headerHeight: 30,
          headerBGC: "rgba(255, 255, 255, 0.2)",
          data: [
            ["海西高速公路网古武线永定至上杭段04", "今天"],
            ["海西高速公路网古武线永定至上杭段03", "今天"],
            ["海西高速公路网古武线永定至上杭段02", "今天"],
            ["海西高速公路网古武线永定至上杭段01", "今天"],
            ["公路行车安全诱导系统", "4个月前"],
            ["航拍修建高铁大型工地施工", "4个月前"],
            ["夕阳下城市建筑工地施工塔吊挖机实拍", "8个月前"],
          ],
          index: true,
          align: ["center"],
          oddRowBGC: "rgba(9, 37, 50, 0.4)",
          evenRowBGC: "rgba(10, 32, 50, 0.3)",
        },

        scrollConfig5: {
          header: ["标题", "更新时间"],
          headerHeight: 30,
          headerBGC: "rgba(255, 255, 255, 0.2)",
          data: [
            ["湖北省取消高速公路省界收费站项目设计04", "1天前"],
            ["湖北省取消高速公路省界收费站项目设计03", "1天前"],
            ["湖北省取消高速公路省界收费站项目设计02", "1天前"],
            ["湖北省取消高速公路省界收费站项目设计01", "1天前"],
            ["海南旅游智慧工地管理系统介绍", "4个月前"],
            ["智能道钉系统宣传视频", "4个月前"],
            ["海南环岛旅游公路智慧工地管理系统介绍", "4个月前"],
            ["智慧小站", "4个月前"],
            ["高速公路智能管控解决方案", "4个月前"],
            ["宣城高铁站", "8个月前"],
          ],
          index: true,
          align: ["center"],
          oddRowBGC: "rgba(9, 37, 50, 0.4)",
          evenRowBGC: "rgba(10, 32, 50, 0.3)",
        },

        scrollConfig6: {
          header: ["标题", "更新时间"],
          headerHeight: 30,
          headerBGC: "rgba(255, 255, 255, 0.2)",
          data: [
            ["思茅至澜沧高速公路勘察设计05", "1天前"],
            ["思茅至澜沧高速公路勘察设计04", "1天前"],
            ["思茅至澜沧高速公路勘察设计03", "1天前"],
            ["思茅至澜沧高速公路勘察设计02", "1天前"],
            ["思茅至澜沧高速公路勘察设计01", "4个月前"],
            ["市政工人施工打磨石雕", "8个月前"],
            ["建筑工程机械施工实拍", "8个月前"],
            ["行业简约工地工人脚手架上施工", "8个月前"],
            ["高速公路智能管控解决方案", "8个月前"],
            ["震撼大气施工企业片头", "8个月前"],
            ["footer", "1年前"],
          ],
          index: true,
          align: ["center"],
          oddRowBGC: "rgba(9, 37, 50, 0.4)",
          evenRowBGC: "rgba(10, 32, 50, 0.3)",
        },

        ringChartConfig: {
          color: ["#F46827", "#4D99FC"],
          lineWidth: 20,
          digitalFlopStyle: {
            fontSize: 20,
            fill: "#fff",
          },

          data: [
            {
              name: "PC端",
              value: 66,
            },
            {
              name: "移动端",
              value: 34,
            },
          ],
        },

        digitalFlopData: [],
        digitalFlopData2: [],
      };
    },
    mounted() {
      const { createData, createData2 } = this;

      createData();

      setInterval(createData, 30000);

      createData2();

      setInterval(createData2, 30000);
    },
    methods: {
      redirectToLogin() {
        // window.location.href = "https://ex.kfer.cn/monitor/login";
        this.$router.push({
          path: "/",
      });
      },
      createData() {
        const { randomExtend,addRandomNumber } = this;

        this.digitalFlopData = [
          {
            name: "当前在线",
            number: [randomExtend(20, 30)],
            content: "{nt}人",
            textAlign: "center",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          {
            name: "今日访问量(IP)",
            number: [addRandomNumber('cip',1,5)],
            content: "{nt}",
            textAlign: "center",
            style: {
              fill: "#f46827",
              fontWeight: "bold",
            },
          },
          {
            name: "今日浏览量(PV)",
            number: [addRandomNumber('cpv',10,50)],
            content: "{nt}",
            textAlign: "center",
            style: {
              fill: "#40faee",
              fontWeight: "bold",
            },
          },
          {
            name: "今日访客数(UV)",
            number: [addRandomNumber('cuv',1,5)],
            content: "{nt}",
            textAlign: "center",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          {
            name: "今日访问频次(VV)",
            number: [addRandomNumber('cvv',10,50)],
            content: "{nt}",
            textAlign: "center",
            style: {
              fill: "#f46827",
              fontWeight: "bold",
            },
          },
          {
            name: "瞬时跳出率",
            number: [addRandomNumber('cjp',1,2)],
            content: "{nt}%",
            textAlign: "center",
            style: {
              fill: "#40faee",
              fontWeight: "bold",
            },
          },
        ];
      },
      createData2() {
        const { randomExtend,addRandomNumber } = this;

        this.digitalFlopData2 = [
          {
            name: "总访问量(IP)",
            number: [addRandomNumber('tip',1,5)],
            content: "{nt}",
            textAlign: "center",
            style: {
              fill: "#f46827",
              fontWeight: "bold",
            },
          },
          {
            name: "总浏览量(PV)",
            number: [addRandomNumber('tpv',10,50)],
            content: "{nt}",
            textAlign: "center",
            style: {
              fill: "#40faee",
              fontWeight: "bold",
            },
          },
          {
            name: "总访客数(UV)",
            number: [addRandomNumber('tuv',1,5)],
            content: "{nt}",
            textAlign: "center",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          {
            name: "总访问频次(VV)",
            number: [addRandomNumber('tvv',10,50)],
            content: "{nt}",
            textAlign: "center",
            style: {
              fill: "#f46827",
              fontWeight: "bold",
            },
          },
          {
            name: "总跳出率",
            number: [addRandomNumber('tjp',1,2)],
            content: "{nt}%",
            textAlign: "center",
            style: {
              fill: "#40faee",
              fontWeight: "bold",
            },
          },
        ];
      },
      randomExtend(minNum, maxNum) {
        if (arguments.length === 1) {
          return parseInt(Math.random() * minNum + 1, 10);
        } else {
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
        }
      },
      addRandomNumber(flag,minNum,maxNum) {
        //current
        if (flag === 'cip') {
          if (arguments.length === 1) {
            this.cip += parseInt(Math.random() * minNum + 1, 10);
          } else {
            this.cip += parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          }
          return this.cip;
        }else if (flag === 'cpv') {
          if (arguments.length === 1) {
            this.cpv += parseInt(Math.random() * minNum + 1, 10);
          } else {
            this.cpv += parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          }
          return this.cpv;
        }else if (flag === 'cuv') {
          if (arguments.length === 1) {
            this.cuv += parseInt(Math.random() * minNum + 1, 10);
          } else {
            this.cuv += parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          }
          return this.cuv;
        }else if (flag === 'cvv') {
          if (arguments.length === 1) {
            this.cvv += parseInt(Math.random() * minNum + 1, 10);
          } else {
            this.cvv += parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          }
          return this.cvv;
        }else if (flag === 'cjp') {
          return this.cjp;
        }

        //total
        if (flag === 'tip') {
          if (arguments.length === 1) {
            this.tip += parseInt(Math.random() * minNum + 1, 10);
          } else {
            this.tip += parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          }
          return this.tip;
        }else if (flag === 'tpv') {
          if (arguments.length === 1) {
            this.tpv += parseInt(Math.random() * minNum + 1, 10);
          } else {
            this.tpv += parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          }
          return this.tpv;
        }else if (flag === 'tuv') {
          if (arguments.length === 1) {
            this.tuv += parseInt(Math.random() * minNum + 1, 10);
          } else {
            this.tuv += parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          }
          return this.tuv;
        }else if (flag === 'tvv') {
          if (arguments.length === 1) {
            this.tvv += parseInt(Math.random() * minNum + 1, 10);
          } else {
            this.tvv += parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          }
          return this.tvv;
        }else if (flag === 'tjp') {
          return this.tjp;
        }

      },
    },
  };
</script>

<style lang="scss" scoped>
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  $primary: #0066ff;
  $secondary: #6be1ff;

  .h3 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .text-primary {
    color: $primary;
  }

  .text-secondary {
    color: $secondary;
  }

  .text-warning {
    color: #f46827;
  }

  .text-info {
    color: #40faee;
  }

  .d-flex {
    display: flex !important;
  }

  .flex-fill {
    flex: 1 1 auto;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-row {
    flex-direction: row;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }
  .w {
    width: 100%;
    &-100 {
      width: 100%;
    }

    &-50 {
      width: 50%;
    }

    &-40 {
      width: 40%;
    }

    &-33 {
      width: 33.333%;
    }

    &-25 {
      width: 25%;
    }

    &-20 {
      width: 20%;
    }
    &-16 {
      width: 16.666%;
    }
    &-10 {
      width: 10%;
    }
  }

  .row {
    display: flex !important;
    flex: 1 !important;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }

  .col {
    padding: 10px;
    display: flex !important;
  }

  .bar-chart {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    &-item {
      display: flex !important;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      height: 30px;
      & + & {
        margin-top: 10px;
      }
      .bar-chart-item-info {
        display: flex !important;
        justify-content: space-between;
        align-items: center;

        font-size: 12px;
      }
      .bar-chart-bar {
        flex: 1 !important;
        height: 100%;
        position: relative;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 5px;
        overflow: hidden;
        &-inner {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: #4d99fc;
          background-image: linear-gradient(to right, $primary, #ffc800);
          border-radius: 5px;
        }
      }
    }
  }
  ::v-deep .border-box-content{
    display: flex !important;
  }
  #data-view {
    width: 100%;
    height: 100%;

    background-color: #030409;
    color: #fff;

    #dv-full-screen-container {
      position: relative;
      background-image: url("./assets/img/bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      box-shadow: 0 0 3px blue;
      display: flex !important;
      flex-direction: column;
      padding: 20px;
    }

    .dv-decoration-6 {
      width: 100%;
      height: 20px;
    }

    .header {
      height: 80px;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      display: flex !important;
      justify-content: center;
      .dv-decoration-11 {
        width: 100%;
        height: 60px;
      }
    }

    .container1 {
      flex: 1 !important;
      display: flex !important;
      &-main {
        flex: 1 !important;
        display: flex !important;
        flex-direction: column;
        width: 100%;
        padding: 20px;
      }
    }

    .text-info-item {
      padding: 10px;
      border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .box {
      flex: 1 !important;
      display: flex !important;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 20px;

      &-header {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        padding-bottom: 0;
        height: 40px;
        .h3 {
          color: $primary;
        }
        &-small {
          font-size: 18px !important;
          height: 40px !important;
          font-weight: bold;
        }
      }
      &-body {
        padding: 10px;
        flex: 1 !important;
        display: flex !important;
        flex-direction: column;
      }

      &.plain {
        box-shadow: none;
        background-color: transparent;
        &:after {
          content: none;
        }
        > .box-header {
          background-image: none;

          height: 40px !important;
        }
      }
    }

    .scroll-board {
      border-radius: 20px;
      overflow: hidden;
      .header {
        height: 30px;
        font-size: 12px;
        font-weight: bold;
      }

      .ceil,
      .header-item {
        &:nth-child(1) {
          width: 40px !important;
        }
        &:nth-child(2) {
          width: calc(100% - 240px) !important;
        }
        &:nth-child(3) {
          width: 100px !important;
        }
        &:nth-child(4) {
          width: 100px !important;
        }
      }
    }

    .dashboard {
      &-1 {
        height: 100px;
        .col {
          flex-direction: column;
          .dv-digital-flop {
            width: 100%;
            height: 30px;
          }
        }
      }

      &-2 {
        flex: 1 !important;
        display: flex !important;
        .row {
          flex: 1 !important;
        }
        //树列布局
        .x-row {
          .box {
            height: 50%;
            &:first-child {
              margin-bottom: 10px;
            }
            &:last-child {
              margin-top: 10px;
            }
          }
        }
      }

      &-3 {
        height: 100px;
        .col {
          flex-direction: column;
          .dv-digital-flop {
            width: 100%;
            height: 30px;
          }
        }
      }
    }
  }
  .login-button {
    position: absolute;
    right: 39px;
    top: 20px;
    width: 100px;
    height: 40px;
    background-color: #0084ff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
</style>

